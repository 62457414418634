import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Slider from "react-slick";
import PropTypes from 'prop-types';
import "slick-carousel/slick/slick.css";
import { Loading } from '../components/Layout';

// Custom styled component for Slider with specific focus and slide styles
const CustomSlider = styled(Slider)`
    &:focus {
        outline: none;
    } 
    .slick-slide {
        position: relative;
        width: ${props => props.theme.scale * props.theme.videoWidth}px;
        height: ${props => props.theme.scale * props.theme.videoHeight - 35}px;
        padding-top: ${props => props.theme.scale * 17}px;
        transition: padding 0.5s, opacity 0.5s, height 0.5s;
        overflow: hidden;
        opacity: 0.8;
    }

    .slick-current {
        padding-top: 0;
        opacity: 1;
        height: ${props => props.theme.scale * props.theme.videoHeight}px;
        .swiper-slide__container {            
            max-height: ${props => props.theme.scale * props.theme.videoHeight}px;
        }
    }
`;

// Styled component for individual slide with specific styles for video and image containers
const StyledSlide = styled.div`
    .swiper-slide__container {
        max-height: ${props => props.theme.scale * props.theme.videoMaxHeight}px;          
        transition: max-height 0.5s;
        overflow: hidden;

        video {
            width: ${props => props.theme.scale * props.theme.videoWidth}px;    
            height: ${props => props.theme.scale * props.theme.videoHeight}px;    
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    h2 { 
        position: absolute;
        color: white; 
        bottom: ${props => props.theme.scale * 30}px;
        left: ${props => props.theme.scale * 55}px;
        right: ${props => props.theme.scale * 55}px;
        font-size: ${props => props.theme.scale * 40}px;
        font-family: 'MuseoSans-700';
        background-color: rgba(0,0,0,0.6);
        padding: ${props => props.theme.scale * 10}px;
    }
`;

// Context for managing video cache state across components
const VideoCacheContext = React.createContext({});

// Custom hook for accessing video cache context
const useVideoCache = () => React.useContext(VideoCacheContext);

// Memoized video component to avoid re-renders unless specific props change
const Vid = memo(({ id, src, img, play, onLoadedData, onEnded, loop }) => {
    const vidRef = useRef(null);
    const { cache, setCache } = useVideoCache();

    useEffect(() => {
        const videoElement = vidRef.current;
        
        // Conditionally loads the video when required to play and not cached
        if (play && !cache[id] && src) {
            videoElement.load();
            setCache(prev => ({ ...prev, [id]: true }));
        }

        // Function to control video playback based on the play prop
        const attemptPlay = async () => {
            if (src) {
                try {
                    if (play) {
                        // No need to add .promise because play() is already a function that returns a promise directly.
                        await videoElement.play();
                    } else {
                        await new Promise(resolve => {
                            videoElement.pause();
                            resolve();
                        });
                    }
                } catch (error) {
                    console.error("Playback error:", error);
                    // We can show a fallback image or video ...
                }
            }
        };
        
        attemptPlay();
    }, [play, cache, setCache, id, src]);

    return (
        <video
            id={id}
            ref={vidRef}
            preload="auto"
            onLoadedData={onLoadedData}
            onEnded={onEnded}
            controls={false}
            poster={img}
            playsInline
            muted={true}
            loop={loop}
            aria-label="Video content"
        >
            {src && <source src={src} type="video/mp4" />}
        </video>
    );
});

// Slide component handling both video and image content
const Slide = ({ content, isActive, onVideoEnd, loop }) => {
    if (!content) return null;

    const { video, image, title } = content;

    return (
        <StyledSlide>
            <div className="swiper-slide__container">
                {video ? (
                    <Vid
                        id={content.id || ''}
                        src={video}
                        img={image}
                        play={isActive}
                        onEnded={onVideoEnd}
                        loop={loop}
                    />
                ) : (
                    <img src={image} alt={title} width="100%" height="100%" />
                )}
            </div>
        </StyledSlide>
    );
};

// Main carousel component managing slide state and rendering the slick carousel
const Carousel = () => {
    const sliderRef = useRef(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [cache, setCache] = useState({});
    const [isReady, setIsReady] = useState(false);
    const timeoutRef = useRef(null);

    const { content = [], loading, scale, layout } = useSelector(state => ({
        content: state.data.videoads || [],
        loading: state.data.loading,
        scale: state.config.scale,
        layout: state.config.layout
    }));

    // Set the slider as ready when content is loaded and available
    const isLooping = useMemo(() => content.length === 1 && content[0].video, [content]);

    useEffect(() => {
        if (!loading && content.length > 0) {
            setIsReady(true);
        }
    }, [loading, content]);

    useEffect(() => {
        // Clear any existing timeout when active slide changes
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timeout if the current slide is an image
        if (content[activeSlide] && !content[activeSlide].video) {
            timeoutRef.current = setTimeout(() => {
                if (sliderRef.current) {
                    sliderRef.current.slickNext();
                }
            }, 10000);  // Transition after 10 seconds for image slides
        }

        // Cleanup function to clear timeout when component unmounts or activeSlide changes
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [activeSlide, content]);

    const settings = {
        className: "center",
        lazyLoad: 'ondemand',
        adaptiveHeight: true,
        centerMode: true,
        infinite: content.length > 1,
        centerPadding: (layout === 2 ? 72 : 60) * scale + 'px',
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        beforeChange: (current, next) => setActiveSlide(next),
    };

    const handleVideoEnd = useCallback(() => {
    if (!isLooping && sliderRef.current) {
        sliderRef.current.slickNext();
    }
    }, [isLooping]);

    const handleSlideChange = (index) => {
        setActiveSlide(index);
    };

    // Show loading component until content is ready
    if (!isReady) return <Loading />;

    return (
        <VideoCacheContext.Provider value={{ cache, setCache }}>
            <div className="slider-container">
                <CustomSlider ref={sliderRef} {...settings} afterChange={handleSlideChange}>
                    {content.map((item, index) => (
                        <Slide
                            key={item.id || index}
                            content={item}
                            isActive={index === activeSlide}
                            onVideoEnd={handleVideoEnd}
                            loop={isLooping}
                        />
                    ))}
                </CustomSlider>
            </div>
        </VideoCacheContext.Provider>
    );
};

// Prop types for validation of props passed to components (JSON schema 👀)
Vid.propTypes = {
    id: PropTypes.string.isRequired,
    src: PropTypes.string,
    img: PropTypes.string,
    play: PropTypes.bool.isRequired,
    onLoadedData: PropTypes.func,
    onEnded: PropTypes.func,
    loop: PropTypes.bool
};

Slide.propTypes = {
    content: PropTypes.shape({
        id: PropTypes.string,
        video: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string
    }),
    isActive: PropTypes.bool.isRequired,
    onVideoEnd: PropTypes.func.isRequired,
    loop: PropTypes.bool.isRequired
};

export default Carousel;